import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import * as firebase from 'firebase';

@Component({
  selector: 'app-add-teacher',
  templateUrl: './add-teacher.component.html',
  styleUrls: ['./add-teacher.component.css']
})
export class AddTeacherComponent implements OnInit {

  loading: boolean = false;

  teacher = {
    name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: ""
  }

  errorMessage: string = "";

  uid: string;

  constructor(private router: Router) { }

  ngOnInit() {
    this.uid = localStorage.getItem("uid");

    if (!localStorage.getItem("isAdmin")) {
      this.router.navigate(["login"]);
      return;
    }
  }

  createTeacher() {
    if (!this.validateForm()) {
      return;
    }

    this.loading = true;

    var func = firebase.functions().httpsCallable("createUser");

    func(this.teacher)
      .then(res => {
        if (res.data.success) {
          this.saveProfile(res.data.data.uid);
        } else {
          this.loading = false;

          this.errorMessage = res.data.data;
        }
      })
      .catch(err => {
        this.loading = false;

        this.errorMessage = err.message;
      })

  }

  saveProfile(uid: string) {
    var profile: any = Object.assign({}, this.teacher);

    delete profile.password;
    delete profile.confirmPassword;

    profile.uid = uid;
    profile.adminId = this.uid;

    firebase.database().ref(`/users/${uid}`).set(profile);

    firebase.auth().currentUser.sendEmailVerification();

    this.router.navigate([`admin`]);
  }

  validateForm() {
    if (!this.teacher.name) {
      this.errorMessage = "Please enter Full Name!";
      return false;
    }

    if (!this.teacher.email) {
      this.errorMessage = "Please enter Email!";
      return false;
    }

    if (!this.teacher.phone) {
      this.errorMessage = "Please enter Contact!";
      return false;
    }

    if (!this.teacher.password) {
      this.errorMessage = "Please enter Password!";
      return false;
    }

    if (this.teacher.password.length < 6) {
      this.errorMessage = "Password must be 6 characters minimum!";
      return false;
    }

    if (this.teacher.password !== this.teacher.confirmPassword) {
      this.errorMessage = "Confirm password does not match!";
      return false;
    }

    this.errorMessage = "";

    return true;
  }

}
