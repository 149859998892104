import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as firebase from 'firebase';
import { UtilsService } from '../utils.service';

@Component({
  selector: 'app-assign-points',
  templateUrl: './assign-points.component.html',
  styleUrls: ['./assign-points.component.css']
})
export class AssignPointsComponent implements OnInit {

  loading: boolean = false;

  classId: string = "";
  teacherId: string = "";

  students: Array<any> = [];

  points: Array<any> = [];

  class: any = {};

  existingCount: number = 0;

  allPoints: any = 0;

  constructor(private route: ActivatedRoute,
    private utils: UtilsService,
    private router: Router) { }

  ngOnInit() {
    let isAdmin = localStorage.getItem("isAdmin") ? true : false;

    if (isAdmin) {
      this.router.navigate([`admin`]);
      return;
    } else {
      this.utils.checkSubscription(this.router);
    }

    this.teacherId = localStorage.getItem("uid");

    this.route.params.subscribe(params => {
      this.classId = params['classId'];

      if (!this.classId) {
        this.router.navigate([`teacher-classes`]);
      } else {
        this.getPoints();
        this.getStudents();
        this.getClass();
      }
    });
  }

  getPoints() {
    firebase.database().ref("/studentPoints")
      .orderByChild("classId")
      .equalTo(this.classId)
      .once("value")
      .then(snapshot => {
        var data = snapshot.val();

        var today = new Date();

        var studentId = "";

        for (var key in data) {
          var temp = data[key];

          var date = new Date(temp.timestamp);

          if (today.getDate() === date.getDate() && today.getMonth() === date.getMonth()) {
            if (studentId) {
              if (studentId === temp.studentId) {
                this.existingCount++;
              }
            } else {
              studentId = temp.studentId;
              this.existingCount++;
            }
          }
        }
      });
  }

  getStudents() {
    this.loading = true;
    firebase.database().ref(`/students/${this.classId}`)
      .once("value")
      .then(snapshot => {
        this.loading = false;

        let data = snapshot.val();

        for (var key in data) {
          let temp = data[key];
          temp.key = key;

          let point = {
            classId: this.classId,
            studentId: key,
            timestamp: Number(new Date()),
            isAbsent: false,
            points: 0,
            uid: this.teacherId,
          }

          this.points.push(point);

          this.students.push(temp);
        }
      })
      .catch(err => {
        console.log(err);
        alert(err.message);
        this.loading = false;
      });
  }

  getClass() {
    firebase.database().ref(`/classes/${this.teacherId}/${this.classId}`)
      .once("value")
      .then(snapshot => {
        if (snapshot.val()) {
          this.class = snapshot.val();
        } else {
          this.router.navigate([`teacher-classes`]);
        }

      })
  }

  assignPointsToAll() {
    this.points.forEach(point => {
      point.points = Number(this.allPoints);
    })
  }

  submitPoints() {
    if(this.existingCount > 1) {
      return;
    }
    
    var promises = [];

    this.loading = true;

    this.points.forEach(point => {
      promises.push(firebase.database().ref(`/studentPoints`).push(point));
    });

    Promise.all(promises)
      .then(() => {
        alert("Points submitted.");
        this.loading = false;
        this.router.navigate([`teacher-classes`]);
      })
  }

}
