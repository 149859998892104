import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as firebase from 'firebase';

@Component({
  selector: 'app-all-classes',
  templateUrl: './all-classes.component.html',
  styleUrls: ['./all-classes.component.css']
})
export class AllClassesComponent implements OnInit {

  loading: boolean = false;

  teacherId: string = "";

  classes: Array<any> = [];
  teacherName: string = "";

  newClass: any = {
    name: ""
  }

  currentClass: any;

  constructor(private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.teacherId = params['teacherId'];

      if (!this.teacherId) {
        this.router.navigate([`admin`]);
      } else {
        this.getClasses();
        this.getTeacherName();
      }
    });
  }

  getClasses() {
    this.loading = true;
    firebase.database().ref(`/classes/${this.teacherId}`)
      .once("value")
      .then(snapshot => {
        this.loading = false;

        let data = snapshot.val();

        for (var key in data) {
          let temp = data[key];
          temp.key = key;

          this.classes.push(temp);
        }
      })
      .catch(err => {
        console.log(err);
        alert(err.message);
        this.loading = false;
      });
  }

  getTeacherName() {
    firebase.database().ref(`/users/${this.teacherId}/name`)
      .once("value")
      .then(snapshot => {
        this.teacherName = snapshot.val();
      })
  }

  saveClass() {
    if (this.classes.length === 6) {
      this.newClass = {
        name: ""
      };
      return alert("You cannot add more than 6 classes!");
    }

    this.newClass.timestamp = Number(new Date());
    this.newClass.uid = this.teacherId;

    this.newClass.key = firebase.database().ref(`/classes/${this.teacherId}`).push(this.newClass).key;

    alert("Class added.");

    document.getElementById("addClose").click();

    this.classes.push(this.newClass);

    this.newClass = {
      name: ""
    };
  }

  removeClass() {
    var key = this.classes[this.currentClass].key;

    firebase.database().ref(`/classes/${this.teacherId}/${key}`).remove();

    this.classes.splice(this.currentClass, 1);

    alert("Class removed.");
    document.getElementById("removeClose").click()
  }

}
