import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import * as firebase from 'firebase';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loading: boolean = false;

  user = {
    email: "",
    password: ""
  }

  errorMessage: string = "";

  constructor(private router: Router) { }

  ngOnInit() {
    if(localStorage.getItem("uid")) {
      if(localStorage.getItem("isAdmin")) {
        this.router.navigate([`admin`]);
      } else {
        this.router.navigate([`teacher-home`]);
      }
    }
  }

  login() {
    if (!this.user.email || !this.user.password) {
      this.errorMessage = "Please enater email and password!";
      return;
    }

    this.loading = true;

    firebase.auth().signInWithEmailAndPassword(this.user.email, this.user.password)
      .then(user => {
        this.getProfile(user.user.uid);
      })
      .catch(err => {
        this.errorMessage = err.message;
        this.loading = false;
      })
  }

  async getProfile(uid) {
    var profileSnapshot = await firebase.database().ref(`/users/${uid}`).once("value");

    if (!profileSnapshot.val()) {
      this.errorMessage = "There is some issue. Please try again later.";
      firebase.auth().signOut();
      return;
    }

    var profile = profileSnapshot.val();

    localStorage.setItem("uid", uid);

    this.loading = false;

    if (profile.isAdmin) {
      localStorage.setItem("isAdmin", "true");
      this.router.navigate([`admin`]);
    } else {
      localStorage.removeItem("isAdmin");
      localStorage.setItem("adminId", profile.adminId);
      this.router.navigate([`teacher-home`]);
    }
  }
}
