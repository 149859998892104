import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import * as firebase from 'firebase';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css']
})
export class AdminHomeComponent implements OnInit {

  loading: boolean = false;

  uid: string;

  teachers: Array<any> = [];

  constructor(private router: Router) { }

  ngOnInit() {
    this.uid = localStorage.getItem("uid");

    if (!localStorage.getItem("isAdmin")) {
      this.router.navigate(["login"]);
      return;
    }

    this.getAllTeachers();
  }

  getAllTeachers() {
    this.loading = true;

    firebase.database().ref("/users")
      .orderByChild("adminId")
      .equalTo(this.uid)
      .once("value")
      .then(snapshot => {
        var data = snapshot.val();

        for (var key in data) {
          var temp = data[key];
          if (temp.subscriptionExpiry) {
            temp.expiry = new Date(temp.subscriptionExpiry);
          }
          this.teachers.push(data[key]);
        }

        this.loading = false;
      })
      .catch(err => {
        alert(err.message);
        this.loading = false;
      })
  }
}
