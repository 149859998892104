import { Component, OnInit } from '@angular/core';

import * as firebase from 'firebase';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  loading: boolean = false;

  user = {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    phone: ""
  }

  errorMessage: string = "";

  constructor(private router: Router) { }

  ngOnInit() {
  }

  signup() {
    if (!this.validateForm()) {
      return;
    }

    this.loading = true;

    firebase.auth().createUserWithEmailAndPassword(this.user.email, this.user.password)
      .then(user => {
        this.saveProfile(user.user.uid);
      })
      .catch(err => {
        this.loading = false;

        this.errorMessage = err.message;
      })
  }

  saveProfile(uid: string) {
    var profile: any = Object.assign({}, this.user);

    delete profile.password;
    delete profile.confirmPassword;

    profile.uid = uid;
    profile.isAdmin = true;

    firebase.database().ref(`/users/${uid}`).set(profile);

    firebase.auth().currentUser.sendEmailVerification();

    alert("A verification email has sent to your email. Please verify your account.");

    this.router.navigate([`login`]);
  }

  validateForm() {
    if (!this.user.name) {
      this.errorMessage = "Please enter Full Name!";
      return false;
    }

    if (!this.user.email) {
      this.errorMessage = "Please enter Email!";
      return false;
    }

    if (!this.user.phone) {
      this.errorMessage = "Please enter Contact!";
      return false;
    }

    if (!this.user.password) {
      this.errorMessage = "Please enter Password!";
      return false;
    }

    if (this.user.password.length < 6) {
      this.errorMessage = "Password must be 6 characters minimum!";
      return false;
    }

    if (this.user.password !== this.user.confirmPassword) {
      this.errorMessage = "Confirm password does not match!";
      return false;
    }

    this.errorMessage = "";

    return true;
  }

}
