import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-points-rules',
  templateUrl: './points-rules.component.html',
  styleUrls: ['./points-rules.component.css']
})
export class PointsRulesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
