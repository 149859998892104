import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import * as firebase from 'firebase';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  uid: string = "";
  isAdmin: boolean = false;

  constructor(private router: Router) { }

  ngOnInit() {
    this.uid = localStorage.getItem("uid");

    this.isAdmin = localStorage.getItem("isAdmin") ? true : false;

    if(!this.uid) {
      this.router.navigate([`login`]);
    }
  }

  logout() {
    firebase.auth().signOut();
    localStorage.clear();

    this.router.navigate([`login`]);
  }

}
