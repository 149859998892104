import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as firebase from 'firebase';
import { UtilsService } from '../utils.service';

@Component({
  selector: 'app-teacher-classes',
  templateUrl: './teacher-classes.component.html',
  styleUrls: ['./teacher-classes.component.css']
})
export class TeacherClassesComponent implements OnInit {

  loading: boolean = false;

  teacherId: string = "";

  classes: Array<any> = [];

  newClass: any = {
    name: ""
  }

  currentClass: any;

  constructor(private route: ActivatedRoute,
    private utils: UtilsService,
    private router: Router) { }

  ngOnInit() {

    let isAdmin = localStorage.getItem("isAdmin") ? true : false;

    if (isAdmin) {
      this.router.navigate([`admin`]);
      return;
    } else {
      this.utils.checkSubscription(this.router);
    }

    this.teacherId = localStorage.getItem("uid");

    this.getClasses();
  }

  getClasses() {
    this.loading = true;
    firebase.database().ref(`/classes/${this.teacherId}`)
      .once("value")
      .then(snapshot => {
        this.loading = false;

        let data = snapshot.val();

        for (var key in data) {
          let temp = data[key];
          temp.key = key;

          this.classes.push(temp);
        }
      })
      .catch(err => {
        console.log(err);
        alert(err.message);
        this.loading = false;
      });
  }

  saveClass() {
    if (this.classes.length === 6) {
      this.newClass = {
        name: ""
      };
      return alert("You cannot add more than 6 classes!");
    }

    this.newClass.timestamp = Number(new Date());
    this.newClass.uid = this.teacherId;

    this.newClass.key = firebase.database().ref(`/classes/${this.teacherId}`).push(this.newClass).key;

    alert("Class added.");

    document.getElementById("addClose").click();

    this.classes.push(this.newClass);

    this.newClass = {
      name: ""
    };
  }

  removeClass() {
    var key = this.classes[this.currentClass].key;

    firebase.database().ref(`/classes/${this.teacherId}/${key}`).remove();

    this.classes.splice(this.currentClass, 1);

    alert("Class removed.");
    document.getElementById("removeClose").click()
  }
}
