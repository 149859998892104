import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxLoadingModule } from 'ngx-loading';

import * as firebase from 'firebase';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { TeacherHomeComponent } from './teacher-home/teacher-home.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { AddTeacherComponent } from './add-teacher/add-teacher.component';
import { AddStudentComponent } from './add-student/add-student.component';
import { AddRulesComponent } from './add-rules/add-rules.component';
import { AssignPointsComponent } from './assign-points/assign-points.component';
import { AllStudentsComponent } from './all-students/all-students.component';
import { ClassPointsComponent } from './class-points/class-points.component';
import { StudentPointComponent } from './student-point/student-point.component';
import { RedeemClassPointsComponent } from './redeem-class-points/redeem-class-points.component';
import { RedeemStdPointsComponent } from './redeem-std-points/redeem-std-points.component';
import { HeaderComponent } from './header/header.component';
import { AllClassesComponent } from './all-classes/all-classes.component';
import { PointsRulesComponent } from './points-rules/points-rules.component';
import { SignupComponent } from './signup/signup.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { TeacherClassesComponent } from './teacher-classes/teacher-classes.component';
import { SubscriptionComponent } from './subscription/subscription.component';


var firebaseConfig = {
  apiKey: "AIzaSyCacptiTrZSjMQlkAp_42wFzCzMGkI8RMQ",
  authDomain: "earn-it-web.firebaseapp.com",
  databaseURL: "https://earn-it-web.firebaseio.com",
  projectId: "earn-it-web",
  storageBucket: "earn-it-web.appspot.com",
  messagingSenderId: "625079170726",
  appId: "1:625079170726:web:bed7189a964463b4"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'teacher-home', component: TeacherHomeComponent },
  { path: 'admin', component: AdminHomeComponent },
  { path: 'add-teacher', component: AddTeacherComponent },
  { path: 'all-classes/:teacherId', component: AllClassesComponent },
  { path: 'add-student', component: AddStudentComponent },
  { path: 'add-rules', component: AddRulesComponent },
  { path: 'assign-points/:classId', component: AssignPointsComponent },
  { path: 'all-students/:classId', component: AllStudentsComponent },
  { path: 'class-points', component: ClassPointsComponent },
  { path: 'std-points', component: StudentPointComponent },
  { path: 'redeem-class-pts', component: RedeemClassPointsComponent },
  { path: 'redeem-std-pts', component: RedeemStdPointsComponent },
  { path: 'points-rules', component: PointsRulesComponent },
  { path: 'signup', component: SignupComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'teacher-classes', component: TeacherClassesComponent },
  { path: 'subscription', component: SubscriptionComponent },

  { path: '', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TeacherHomeComponent,
    AdminHomeComponent,
    AddTeacherComponent,
    AddStudentComponent,
    AddRulesComponent,
    AssignPointsComponent,
    AllStudentsComponent,
    ClassPointsComponent,
    StudentPointComponent,
    RedeemClassPointsComponent,
    RedeemStdPointsComponent,
    HeaderComponent,
    AllClassesComponent,
    PointsRulesComponent,
    SignupComponent,
    ResetPasswordComponent,
    TeacherClassesComponent,
    SubscriptionComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false } // <-- debugging purposes only
    ),
    FormsModule,
    ReactiveFormsModule,
    NgxLoadingModule.forRoot({
      fullScreenBackdrop: true
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
