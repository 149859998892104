import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  checkSubscription(router: Router) {
    let uid = localStorage.getItem("uid");

    return firebase.database().ref(`/users/${uid}/subscriptionExpiry`)
      .once("value")
      .then(snapshot => {
        var expiry = snapshot.val();

        if (!expiry) {
          var date = new Date();

          date.setDate(date.getDate() + 7);

          var str = date.toISOString();

          firebase.database().ref(`/users/${uid}/subscriptionExpiry`).set(str);

          return date;
        } else {
          var date = new Date(expiry);

          var today = new Date();

          if (date < today) {
            router.navigate([`subscription`]);
            return false;
          } else {
            return date;
          }
        }
      })
  }
}
