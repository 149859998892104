import { Component, OnInit } from '@angular/core';

import * as firebase from 'firebase';
import { Router } from '@angular/router';

declare var Stripe: any;

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {

  showCard = false;

  loading = false;

  selectedPlan: any = "9.99";

  plans = [
    {
      name: "Monthly Subscription",
      price: 9.99,
      priceText: "$9.99/Month",
    },
    {
      name: "Yearly Subscription",
      price: 99.99,
      priceText: "$99.99/Month"
    }
  ]
  
  card = {
    name: "",
    number: "",
    cvc: "",
    exp_month: "1",
    exp_year: "2020"
  }

  user: any = {};

  constructor(private router: Router) { }

  ngOnInit() {
    Stripe.setPublishableKey('pk_test_cadzJyPyXwZJ08T4Z8mEdQCy');

    var uid = localStorage.getItem("uid");

    firebase.database().ref(`/users/${uid}`)
      .once("value")
      .then(snapshot => {
        this.user = snapshot.val();
      });
  }

  addCard() {
    if (!this.card.name || !this.card.number || !this.card.cvc) {
      return alert("Please enter card details!");
    }

    this.loading = true;

    Stripe.card.createToken(this.card, (status, response) => {
      if (response.error) { // Problem!
        this.loading = false;
        alert(response.error.message);
      } else { // Token was created!

        // Get the token ID:
        var token = response.id;
        debugger;
        var params: any = {
          email: this.user.email,
          token: token,
        }

        if (this.user.customerId) {
          params.customerId = this.user.customerId;
        }

        firebase.functions().httpsCallable("addCardToCustomer")(params)
          .then(res => {
            this.loading = false;
            if (res.data.success) {
              firebase.database().ref(`/users/${this.user.uid}/customerId`).set(res.data.data.customer_id);
              var cardData = {
                last4: response.card.last4,
                name: this.card.name
              }

              firebase.database().ref(`/users/${this.user.uid}/cardData`).set(cardData);

              this.user.customerId = res.data.data.customer_id;
              this.user.cardData = cardData;
              this.showCard = false;
            } else {
              alert(res.data.message);
            }
          })
          .catch(err => {
            this.loading = false;
            alert(err);
          })
      }
    });
  }

  chargeCustomer() {
    this.loading = true;
    firebase.functions().httpsCallable("chargeCustomer")({
      customerId: this.user.customerId,
      amount: Number(this.selectedPlan) * 100
    })
    .then(res => {
      this.loading = false;
      if(res.data.success) {
        var date = new Date();

        if(this.selectedPlan === "9.99" || this.selectedPlan === 9.99) {
          date.setMonth(date.getMonth() + 1);
        } else {
          date.setFullYear(date.getFullYear() + 1);
        }

        this.user.subscriptionExpiry = date.toISOString();

        firebase.database().ref(`/users/${this.user.uid}/subscriptionExpiry`).set(this.user.subscriptionExpiry);

        firebase.database().ref(`/payments/${this.user.uid}`).push(res.data.data);

        this.router.navigate([`teacher-home`]);
      } else {
        alert(res.data.message);
      }
    })
  }
}
