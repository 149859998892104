import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import * as firebase from 'firebase';
import * as moment from 'moment';
import { UtilsService } from '../utils.service';

declare var jspdf: any;

@Component({
  selector: 'app-student-point',
  templateUrl: './student-point.component.html',
  styleUrls: ['./student-point.component.css']
})
export class StudentPointComponent implements OnInit {

  loading: boolean = false;

  teacherId: string = "";

  currentClass: any;

  currentStudent: any;
  studentModel: any;

  allClasses: Array<any> = [];

  students: Array<any> = [];

  allPoints: any = {};

  currentDate: any = "";

  dates = {
    selectedDate: {
      date: "",
      milliseconds: 0
    },
    weekEndDate: {
      date: "",
      milliseconds: 0
    },
    monthEndDate: {
      date: "",
      milliseconds: 0
    }
  }

  pdfHtml: any;

  constructor(private router: Router, private utils: UtilsService) { }

  ngOnInit() {
    let isAdmin = localStorage.getItem("isAdmin") ? true : false;

    if (isAdmin) {
      this.router.navigate([`admin`]);
      return;
    } else {
      this.utils.checkSubscription(this.router);
    }

    this.teacherId = localStorage.getItem("uid");

    var today = new Date();

    today.setDate(today.getDate() - 7);

    this.currentDate = moment(today).format("YYYY-MM-DD");

    this.getClasses();
  }

  getClasses() {
    this.loading = true;
    this.allClasses = [];
    firebase.database().ref(`/classes/${this.teacherId}`)
      .once("value")
      .then(snapshot => {
        if (snapshot.val()) {
          var data = snapshot.val();

          for (var key in data) {
            var temp = data[key];
            temp.key = key;

            this.allClasses.push(temp);
          }

          this.currentClass = this.allClasses[0];

          this.getStudents();
        } else {
          alert("No classes found.");
          this.router.navigate([`teacher-classes`]);
        }
      })
  }

  generateReport() {

    if (!this.currentDate) {
      return alert("Please select start date!");
    }

    this.currentStudent = this.studentModel;
    this.setPoints();

    this.makePdf()

    /* if (this.studentModel.key === this.currentStudent.key) {
      this.setPoints();
    } else {
      this.currentStudent = this.studentModel;
      this.setPoints();
    } */
  }

  getStudents() {
    this.loading = true;
    this.students = [];
    firebase.database().ref(`/students/${this.currentClass.key}`)
      .once("value")
      .then(snapshot => {
        this.loading = false;

        let data = snapshot.val();

        for (var key in data) {
          let temp = data[key];
          temp.key = key;
          temp.weeklyPoints = 0;
          temp.monthlyPoints = 0;
          temp.totalPoints = 0;

          this.students.push(temp);
        }

        this.currentStudent = this.students[0];
        this.studentModel = this.students[0];

        this.getPoints();
      })
      .catch(err => {
        console.log(err);
        alert(err.message);
        this.loading = false;
      });
  }

  getPoints() {
    firebase.database().ref("/studentPoints")
      .orderByChild("classId")
      .equalTo(this.currentClass.key)
      .once("value")
      .then(snapshot => {
        var data = snapshot.val();

        this.allPoints = data;

        this.setPoints();
      });
  }

  setPoints() {
    var data = this.allPoints;

    this.students.forEach(student => {
      student.totalPoints = 0;
      student.weeklyPoints = 0;
      student.monthlyPoints = 0;
    })

    this.getDates();

    for (var key in data) {
      var temp = data[key];

      this.students.forEach(student => {
        if (student.key === temp.studentId) {
          if (!temp.isAbsent) {
            if (temp.timestamp >= this.dates.selectedDate.milliseconds) {

              student.totalPoints += temp.points;

              if (temp.timestamp <= this.dates.weekEndDate.milliseconds) {
                student.weeklyPoints += temp.points;
                student.monthlyPoints += temp.points;
              } else if (temp.timestamp <= this.dates.monthEndDate.milliseconds) {
                student.monthlyPoints += temp.points;
              }
            }
          }
        }
      })
    }
  }

  getDates() {
    var start = new Date(this.currentDate);
    this.dates.selectedDate.milliseconds = start.setHours(0, 0, 1);

    this.dates.selectedDate.date = moment(this.dates.selectedDate.milliseconds).format("MM/DD/YYYY");

    start.setHours(23, 59, 59);

    this.dates.weekEndDate.milliseconds = start.setDate(start.getDate() + 7);
    this.dates.weekEndDate.date = moment(this.dates.weekEndDate.milliseconds).format("MM/DD/YYYY");

    this.dates.monthEndDate.milliseconds = start.setDate(start.getDate() + 23);
    this.dates.monthEndDate.date = moment(this.dates.monthEndDate.milliseconds).format("MM/DD/YYYY");
  }

  makePdf() {
    this.pdfHtml = document.getElementById("test").innerHTML;

    var temp: any = document.getElementById("pdfForm");

    setTimeout(() => {
      temp.submit();
    }, 1000);

    /* let doc = new jspdf();
    doc.addHTML(document.getElementById("test"), function () {
      doc.save("obrz.pdf");
    }); */
  }
}
