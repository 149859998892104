import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import * as firebase from 'firebase';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  email: string = "";
  loading: boolean = false;

  constructor(private router: Router) { }

  ngOnInit() {
    if (localStorage.getItem("uid")) {
      if (localStorage.getItem("isAdmin")) {
        this.router.navigate([`admin`]);
      } else {
        this.router.navigate([`teacher-home`]);
      }
    }
  }

  resetPassword() {
    if (!this.email) {
      return alert("Please enter email address!");
    }

    this.loading = true;

    firebase.auth().sendPasswordResetEmail(this.email)
      .then(() => {
        alert("A password reset link has sent to your email.");
        this.loading = false;
        this.router.navigate([`login`]);
      })
      .catch(err => {
        alert(err.message);
        this.loading = false;
      })
  }

}
