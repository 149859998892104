import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import * as firebase from 'firebase';
import { UtilsService } from '../utils.service';

@Component({
  selector: 'app-all-students',
  templateUrl: './all-students.component.html',
  styleUrls: ['./all-students.component.css']
})
export class AllStudentsComponent implements OnInit {

  loading: boolean = false;

  classId: string = "";
  teacherId: string = "";

  students: Array<any> = [];
  class: any = {};

  newStudent: any = {
    firstName: "",
    lastName: ""
  }

  currentStudent: any;

  constructor(private route: ActivatedRoute,
    private utils: UtilsService,
    private router: Router) { }

  ngOnInit() {
    let isAdmin = localStorage.getItem("isAdmin") ? true : false;

    if (isAdmin) {
      this.router.navigate([`admin`]);
      return;
    } else {
      this.utils.checkSubscription(this.router);
    }

    this.teacherId = localStorage.getItem("uid");

    this.route.params.subscribe(params => {
      this.classId = params['classId'];

      if (!this.classId) {
        this.router.navigate([`teacher-classes`]);
      } else {
        this.getStudents();
        this.getClass();
      }
    });
  }

  getStudents() {
    this.loading = true;
    firebase.database().ref(`/students/${this.classId}`)
      .once("value")
      .then(snapshot => {
        this.loading = false;

        let data = snapshot.val();

        for (var key in data) {
          let temp = data[key];
          temp.key = key;

          this.students.push(temp);
        }
      })
      .catch(err => {
        console.log(err);
        alert(err.message);
        this.loading = false;
      });
  }

  getClass() {
    firebase.database().ref(`/classes/${this.teacherId}/${this.classId}`)
      .once("value")
      .then(snapshot => {
        if (snapshot.val()) {
          this.class = snapshot.val();
        } else {
          this.router.navigate([`teacher-classes`]);
        }

      })
  }

  editStudent(index) {
    this.currentStudent = index;
    this.newStudent = this.students[this.currentStudent];
  }

  saveStudent() {

    this.newStudent.timestamp = Number(new Date());
    this.newStudent.uid = this.teacherId;
    this.newStudent.classId = this.classId;

    if (this.newStudent.key) {
      this.newStudent.key = firebase.database().ref(`/students/${this.classId}/${this.newStudent.key}`).set(this.newStudent);
      alert("Student updated.");
      this.students[this.currentStudent] = this.newStudent;
    } else {
      this.newStudent.key = firebase.database().ref(`/students/${this.classId}`).push(this.newStudent).key;
      alert("Student added.");
      this.students.push(this.newStudent);
    }

    document.getElementById("addClose").click();

    this.newStudent = {
      firstName: "",
      lastName: ""
    };
  }

  removeStudent() {
    var key = this.students[this.currentStudent].key;

    firebase.database().ref(`/students/${this.classId}/${key}`).remove();

    this.students.splice(this.currentStudent, 1);

    alert("Student removed.");
    document.getElementById("removeClose").click()
  }

}
