import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilsService } from '../utils.service';

@Component({
  selector: 'app-teacher-home',
  templateUrl: './teacher-home.component.html',
  styleUrls: ['./teacher-home.component.css']
})
export class TeacherHomeComponent implements OnInit {

  public expiry: Date;

  constructor(private router: Router, private utils: UtilsService) { }

  ngOnInit() {
    let isAdmin = localStorage.getItem("isAdmin") ? true : false;

    if (isAdmin) {
      this.router.navigate([`admin`]);
      return;
    } else {
      this.utils.checkSubscription(this.router)
        .then(res => {
          if (res) {
            this.expiry = res;
          }
        })
    }
  }

}
