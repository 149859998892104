import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import * as firebase from 'firebase';
import { UtilsService } from '../utils.service';

@Component({
  selector: 'app-redeem-class-points',
  templateUrl: './redeem-class-points.component.html',
  styleUrls: ['./redeem-class-points.component.css']
})
export class RedeemClassPointsComponent implements OnInit {

  loading: boolean = false;

  teacherId: string = "";

  currentClass: any;

  allClasses: Array<any> = [];

  students: Array<any> = [];

  constructor(private router: Router, private utils: UtilsService) { }

  ngOnInit() {
    let isAdmin = localStorage.getItem("isAdmin") ? true : false;

    if (isAdmin) {
      this.router.navigate([`admin`]);
      return;
    } else {
      this.utils.checkSubscription(this.router);
    }

    this.teacherId = localStorage.getItem("uid");

    this.getClasses();
  }

  getClasses() {
    this.loading = true;
    this.allClasses = [];
    firebase.database().ref(`/classes/${this.teacherId}`)
      .once("value")
      .then(snapshot => {
        if (snapshot.val()) {
          var data = snapshot.val();

          for (var key in data) {
            var temp = data[key];
            temp.key = key;

            this.allClasses.push(temp);
          }

          this.currentClass = this.allClasses[0];

          this.getStudents();
        } else {
          alert("No classes found.");
          this.router.navigate([`teacher-classes`]);
        }
      })
  }

  getStudents() {
    this.loading = true;
    this.students = [];
    firebase.database().ref(`/students/${this.currentClass.key}`)
      .once("value")
      .then(snapshot => {
        this.loading = false;

        let data = snapshot.val();

        for (var key in data) {
          let temp = data[key];
          temp.key = key;

          temp.totalPoints = 0;

          if (!temp.redeemedPoints) {
            temp.redeemedPoints = 0;
          }

          temp.pointsToRedeem = 0;

          this.students.push(temp);
        }

        this.getPoints();
      })
      .catch(err => {
        console.log(err);
        alert(err.message);
        this.loading = false;
      });
  }

  getPoints() {
    firebase.database().ref("/studentPoints")
      .orderByChild("classId")
      .equalTo(this.currentClass.key)
      .once("value")
      .then(snapshot => {
        var data = snapshot.val();

        for (var key in data) {
          var temp = data[key];

          this.students.forEach(student => {
            if (student.key === temp.studentId) {
              if (!temp.isAbsent) {
                student.totalPoints += temp.points;
              }
            }
          })
        }
      });
  }

  redeemPoints() {
    var isValid = true;

    var updates: any = {};

    this.students.forEach(student => {
      if ((student.totalPoints - student.redeemedPoints - student.pointsToRedeem) < 0) {
        isValid = false;
      } else {
        updates[`/students/${this.currentClass.key}/${student.key}/redeemedPoints`] = student.redeemedPoints + student.pointsToRedeem;
      }
    });

    if (!isValid) {
      return alert("Please fix all errors!");
    }


    firebase.database().ref().update(updates)
      .then(() => {
        alert("Points redeemed.");
        this.router.navigate([`teacher-classes`]);
      })

  }

}
